<template>
  <div>
    <CModal
      :title="
        inventoryExport && inventoryExport.Id
          ? `Cập nhật Xuất Kho ${$moment(inventoryExport.ExportDate).format(
              'DD/MM/YYYY HH:mm'
            )}`
          : 'Tạo Xuất Kho'
      "
      :show.sync="detailModal"
      :closeOnBackdrop="false"
      centered
      size="xl"
    >
      <div v-if="loading" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <template
        v-if="inventoryExport && inventoryExport.Id"
        v-slot:body-wrapper
      >
        <div
          class="modal-body overflow-auto"
          :style="`height: calc(100vh - 12.1rem);`"
        >
          <div class="form-row">
            <CCol md="3">
              <CInput
                :value="inventoryExport.Warehouse.Name"
                label="Kho xuất"
                placeholder="Kho xuất"
                disabled
              />
            </CCol>
            <CCol md="3">
              <CInput
                :value="
                  inventoryExport.Import && inventoryExport.Import.Warehouse
                    ? inventoryExport.Import.Warehouse.Name
                    : ''
                "
                label="Xuất đến kho"
                placeholder="Kho nhập"
                disabled
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-if="!allowEdit"
                :value="
                  $moment(inventoryExport.ExportDate).format('DD/MM/YYYY HH:mm')
                "
                label="Thời gian xuất kho"
                placeholder="Thời gian xuất kho"
                disabled
              />
              <div v-else role="group" class="form-group">
                <label>
                  Thời gian xuất kho
                </label>
                <datetime
                  type="datetime"
                  v-model="inventoryExport.ExportDate"
                  format="dd/MM/yyyy HH:mm"
                  :input-class="{
                    'form-control': true,
                    'is-invalid': !!inValidObject['inventoryExport.ExportDate'],
                  }"
                  :class="{
                    'is-invalid': !!inValidObject['inventoryExport.ExportDate'],
                  }"
                  value-zone="local"
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryExport.ExportDate"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <div role="group" class="form-group">
                <label>
                  Cước giao hàng
                </label>
                <money
                  :disabled="!allowEdit"
                  placeholder="Cước giao hàng"
                  :value="inventoryExport.ShippingFee"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': !!inValidObject[
                      'inventoryExport.ShippingFee'
                    ],
                  }"
                  @input="
                    (e) => {
                      inventoryExport.ShippingFee = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }
                  "
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryExport.ShippingFee"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <div role="group" class="form-group">
                <label>
                  Cước chành xe
                </label>
                <money
                  :disabled="!allowEdit"
                  placeholder="Cước chành xe"
                  :value="inventoryExport.StrappingFee"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': !!inValidObject[
                      'inventoryExport.StrappingFee'
                    ],
                  }"
                  @input="
                    (e) => {
                      inventoryExport.StrappingFee = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }
                  "
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryExport.StrappingFee"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryExport.WarehouseKeeper"
                label="Thủ kho"
                placeholder="Thủ kho"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject[
                    'inventoryExport.WarehouseKeeper'
                  ],
                }"
                :invalid-feedback="
                  inValidObject['inventoryExport.WarehouseKeeper']
                "
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryExport.Driver"
                label="Tài xế"
                placeholder="Tài xế"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryExport.Driver'],
                }"
                :invalid-feedback="inValidObject['inventoryExport.Driver']"
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryExport.Receiver"
                label="Người nhận hàng"
                placeholder="Người nhận hàng"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryExport.Receiver'],
                }"
                :invalid-feedback="inValidObject['inventoryExport.Receiver']"
              />
            </CCol>
            <CCol md="6">
              <CInput
                v-model="inventoryExport.Note"
                label="Ghi chú"
                placeholder="Ghi chú"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryExport.Note'],
                }"
                :invalid-feedback="inValidObject['inventoryExport.Note']"
              />
            </CCol>
          </div>

          <CRow>
            <CCol md="12">
              <div class="table-responsive">
                <table class="table" ref="lstOrderDetails">
                  <thead>
                    <tr class="bg-light text-dark">
                      <th>STT</th>
                      <th style="min-width:200px;max-width:250px;">
                        Tên SP
                      </th>
                      <th
                        class="text-right"
                        style="min-width:100px;max-width:150px;"
                      >
                        SL đề xuất
                      </th>
                      <th
                        class="text-right"
                        style="min-width:100px;max-width:150px;"
                      >
                        SL thực xuất
                      </th>
                      <th style="min-width:100px;max-width:200px;">
                        Ghi chú
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="exportItems && exportItems.length">
                      <tr
                        v-for="(exportItem, i) in exportItems"
                        :key="`exportItem-${i}`"
                      >
                        <td>{{ i + 1 }}.</td>
                        <td style="min-width:200px;max-width:250px;">
                          {{ exportItem.ProductName }}
                        </td>
                        <td
                          class="text-right"
                          style="min-width:100px;max-width:150px;"
                        >
                          {{ exportItem.QtyOrder }}
                        </td>
                        <td
                          class="text-right"
                          style="min-width:100px;max-width:150px;"
                        >
                          <input
                            v-if="allowEdit && inventoryExport.ImportId"
                            type="number"
                            class="form-control form-control-sm d-inline-block"
                            v-model="exportItem.QtyActual"
                            style="width:70px;"
                          />
                          <span v-else>{{ exportItem.QtyActual }}</span>
                        </td>
                        <td style="min-width: 100px;max-width:200px;">
                          {{ exportItem.Note }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>

          <div v-if="inValidObject['exportItems']" class="text-danger">
            {{ inValidObject["exportItems"] }}
          </div>

          <CRow>
            <CCol md="3">
              <CSelect
                v-if="
                  inventoryExport &&
                    inventoryExport.Id &&
                    !inventoryExport.OrderId &&
                    exportStatusOptions &&
                    exportStatusOptions.length
                "
                label="Trạng thái Xuất Kho:"
                placeholder="Trạng thái"
                :value.sync="inventoryExportStatus"
                :options="[
                  { value: null, label: 'Chọn Trạng Thái Xuất Kho' },
                  ...exportStatusOptions,
                ]"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryExportStatus'],
                }"
                :invalid-feedback="inValidObject['inventoryExportStatus']"
              />
            </CCol>
          </CRow>
        </div>
      </template>

      <template v-slot:footer>
        <div class="form-row w-100">
          <CCol
            class="text-left col-4"
            v-if="
              inventoryExport &&
                inventoryExport.Status != $const.EXPORT_STATUS.Cancelled
            "
          >
            <receipt :inventoryExport="inventoryExport" />
          </CCol>
          <CCol class="text-right col-8 ml-auto">
            <template v-if="allowEdit">
              <CButton
                class="btn btn-primary"
                :disabled="saving"
                @click="saveInventoryExport"
              >
                <CIcon name="cil-save" custom-classes="c-icon m-0" />
                Cập nhật
              </CButton>
              <div class="d-inline-block font-italic" v-if="saving">
                <CSpinner color="info" size="sm" class="ml-2" />
                Đang xử lý...
              </div>
            </template>
            <CButton
              class="btn btn-secondary ml-2"
              @click="detailModal = false"
            >
              <span>×</span>
              Đóng
            </CButton>
          </CCol>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import Receipt from "./Receipt.vue";

export default {
  props: ["showing", "exportId"],
  components: {
    Datetime,
    Receipt,
  },
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      inventoryExport: null,
      saving: false,
      loading: false,
      updating: false,
      editingDetail: null,
      exportItems: [],
      inventoryExportStatus: null,
    };
  },

  async mounted() {
    await this.loadExportInfo(this.exportId);
  },

  computed: {
    allowEdit() {
      let allow = false;
      let exportObj = this.inventoryExport;
      if (exportObj) {
        if (exportObj.Id) {
          return (
            !exportObj.OrderId &&
            exportObj.Status == this.$const.EXPORT_STATUS.Waiting
          );
        } else {
          allow = true;
        }
      }
      return allow;
    },

    exportStatusOptions() {
      let inventoryExport = this.inventoryExport;
      let result = [];

      if (inventoryExport) {
        if (inventoryExport.Status == this.$const.EXPORT_STATUS.Waiting) {
          // ImportId
          if (inventoryExport.ImportId) {
            result = [
              this.$const.EXPORT_STATUS.OnGoing,
              this.$const.EXPORT_STATUS.Cancelled,
            ];
          } else {
            result = [this.$const.EXPORT_STATUS.Cancelled];
          }
        }
      }

      return result
        ? result.map((_) => {
            return { value: _, label: this.$const.EXPORT_STATUS_TEXT[_] };
          })
        : [];
    },
  },

  watch: {
    async showing(val) {
      this.detailModal = val;
      if (!val) {
        this.$router
          .push({
            name: "InventoryExport",
            query: {},
          })
          .catch(() => {});
      }
      await this.loadExportInfo(this.exportId);
    },
    detailModal(val) {
      this.$emit("update:showing", val);
    },
    // async exportId(val) {
    //   await this.loadExportInfo(val);
    // },
  },

  methods: {
    async loadExportInfo(exportId) {
      let result = this.newExportInfo();
      this.loading = true;
      try {
        if (exportId) {
          result = null;

          let filters = [];
          filters.push(`Id eq ${exportId}`);
          let resp = await this.$http.get(`odata/InventoryExport`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $expand:
                `InventoryExportItems($filter=Status eq ${this.$const.STATUS.Active}), ` +
                `Warehouse($select=Name),Import($expand=Warehouse($select=Name);$select=Id)`,
            },
          });
          if (resp && resp.status == 200 && resp.data.value.length) {
            result = resp.data.value[0];
          }
        }
      } catch {
        //
      }
      this.loading = false;
      this.inventoryExport = result;
      this.exportItems = result.InventoryExportItems;
      this.inventoryExportStatus = null;
      this.inValidObject = {};
    },

    newExportInfo() {
      return {
        WarehouseId: null,
        FromWarehouseId: null,
        ExportDate: new Date().toISOString(),
        ShippingCpny: null,
        ShippingFee: 0,
        StrappingFee: 0,
        WarehouseKeeper: null,
        Driver: null,
        Security: null,
        Shipper: null,
        Note: null,
        InventoryExportItems: [],
      };
    },

    updateExportDetail(product) {
      if (this.exportItems.some((_) => _.ProductId == product.ProductId)) {
        // update item detail
        this.exportItems
          .filter((_) => _.ProductId == product.ProductId)
          .forEach((_) => {
            _.QtyOrder = product.QtyOrder;
            _.QtyActual = product.QtyActual;
            _.Note = product.Note;
          });
      } else {
        // insert into export details
        let id = this.$func.create_UUID();
        product.id = id;
        this.exportItems.push(product);
      }
    },

    async saveInventoryExport() {
      let inventoryExport = this.inventoryExport;
      let exportItems = this.exportItems;
      let inventoryExportStatus = this.inventoryExportStatus;

      this.inValidObject = this.checkValid(
        inventoryExport,
        exportItems,
        inventoryExportStatus
      );

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }
      if (!exportItems || !exportItems.length) {
        alert("Chưa có thông tin sản phẩm");
        return;
      }
      this.saving = true;
      try {
        if (inventoryExport.Id) {
          let patchData = {
            Driver: inventoryExport.Driver,
            Note: inventoryExport.Note,
            Receiver: inventoryExport.Receiver,
            ShippingFee: inventoryExport.ShippingFee,
            StrappingFee: inventoryExport.StrappingFee,
            WarehouseKeeper: inventoryExport.WarehouseKeeper,
          };
          if (inventoryExportStatus) {
            patchData["Status"] = inventoryExportStatus;
          }

          await this.$func.asyncForEach(exportItems, async (exportItem) => {
            await this.$http.patch(
              `odata/InventoryExportItem/${exportItem.Id}`,
              {
                QtyActual: parseInt(exportItem.QtyActual),
              }
            );
          });

          let resp = await this.$http.patch(
            `odata/InventoryExport/${inventoryExport.Id}`,
            patchData
          );

          if (resp && resp.status == 204) {
            alert(`Cập nhật Xuất Kho thành công!`);
            this.detailModal = false;
            this.$emit("reload");
          } else if (resp.status == 200 && resp.data.message) {
            let message = this.$const.MESSAGE[resp.data.message]
              ? this.$const.MESSAGE[resp.data.message]
              : resp.data.message;
            let data = resp.data.data;
            if (data) {
              message += ": " + data;
            }
            alert(message);
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(inventoryExport, exportItems, inventoryExportStatus) {
      let inValidObject = {};

      if (!inventoryExport.WarehouseId) {
        inValidObject["inventoryExport.WarehouseId"] = "Vui lòng chọn Kho Xuất";
      }
      if (!inventoryExport.ExportDate) {
        inValidObject["inventoryExport.ExportDate"] =
          "Vui lòng chọn Ngày Xuất Kho";
      }
      // if (!inventoryExport.WarehouseKeeper) {
      //   inValidObject["inventoryExport.WarehouseKeeper"] =
      //     "Vui lòng nhập Thủ Kho";
      // }
      if (
        inventoryExport.ImportId &&
        inventoryExportStatus == this.$const.EXPORT_STATUS.OnGoing &&
        exportItems &&
        exportItems.some((_) => !_.QtyActual)
      ) {
        inValidObject["exportItems"] = "Vui lòng bổ sung Thực xuất!";
      }

      return inValidObject;
    },
  },
};
</script>
