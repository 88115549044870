<template>
  <div>
    <div class="form-row">
      <CCol md="4">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-5 col-md-4 text-right">
              Kho
            </label>
            <v-select
              v-model="warehouseId"
              :options="
                warehouses.map((w) => {
                  return {
                    Id: w.Id,
                    Name: w.Name,
                  };
                })
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Kho"
              class="col-7 col-md-8"
            />
          </div>
        </div>
      </CCol>

      <CCol md="3">
        <div role="group" class="form-group form-row">
          <label class="col-5 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-7">
            <datetime
              type="date"
              v-model="exportDateFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <div role="group" class="form-group form-row">
          <label class="col-5 text-right col-form-label">
            Đến ngày
          </label>
          <div class="col-7">
            <datetime
              type="date"
              v-model="exportDateTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="4">
        <CSelect
          label="Trạng thái"
          placeholder="Trạng thái"
          :value.sync="exportStatus"
          :options="[
            { value: null, label: 'Tất cả' },
            ...Object.keys($const.EXPORT_STATUS_TEXT).map((_) => {
              return { value: _, label: $const.EXPORT_STATUS_TEXT[_] };
            }),
          ]"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
        />
      </CCol>

      <CCol md="3" class="text-center">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      warehouseId: null,
      exportDateFrom: null,
      exportDateTo: null,
      exportStatus: null,
      warehouses: [],
    };
  },
  async mounted() {
    this.warehouses = await this.loadWarehouses();
  },
  methods: {
    async loadWarehouses() {
      let warehouses = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/InventoryWarehouse", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
        },
      });
      if (resp && resp.status == 200) {
        warehouses = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return warehouses;
    },

    filter() {
      let filters = [];
      this.warehouseId && filters.push(`WarehouseId eq ${this.warehouseId}`);
      if (this.exportDateFrom) {
        let exportDateFrom = this.$moment(this.exportDateFrom).toISOString();
        filters.push(`ExportDate ge ${exportDateFrom}`);
      }
      if (this.exportDateTo) {
        let exportDateTo = this.$moment(this.exportDateTo)
          .add(1, "days")
          .toISOString();
        filters.push(`ExportDate lt ${exportDateTo}`);
      }
      this.exportStatus && filters.push(`Status eq ${this.exportStatus}`);

      this.$emit("done", filters);
    },

    clear() {
      this.warehouseId = null;
      this.exportDateFrom = null;
      this.exportDateTo = null;
      this.exportStatus = null;
    },
  },
};
</script>
