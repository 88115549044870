var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilShareBoxed"}}),_vm._v(" DS Xuất Kho ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1)],1)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{on:{"done":function (e) { return _vm.doFilter(e); }}}):_vm._e(),_c('odata-table',{ref:"lstInventoryExport",attrs:{"url":"odata/InventoryExport","tableClass":"table-striped","filter":_vm.filter,"expand":"Warehouse($select=Name)," +
            "InventoryExportItems($top=1;$count=true;$select=ProductName,QtyActual;$filter=Status eq " + (this.$const.STATUS.Active) + ")","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Warehouse: {
            field: 'Warehouse/Name',
            display: 'Kho',
            sortable: false,
            style: 'min-width: 100px',
          },

          ExportDate: {
            field: 'ExportDate',
            display: 'Ngày xuất',
            sortable: true,
            style: 'min-width: 120px',
          },
          WarehouseKeeper: {
            field: 'WarehouseKeeper',
            display: 'Thủ kho',
            sortable: true,
            style: 'min-width: 120px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          ExportDetail: {
            field: 'ExportDetail',
            display: 'Chi tiết',
            sortable: false,
            style: 'min-width: 200px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return [(_vm.$refs.lstInventoryExport.loading)?_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]):_vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(i + 1 + (_vm.$refs.lstInventoryExport.page - 1) * _vm.pageSize)+". ")])]),_c('td',[(row.Warehouse)?_c('span',[_vm._v(" "+_vm._s(row.Warehouse ? row.Warehouse.Name : "")+" ")]):_vm._e()]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":"Chi tiết Nhập Kho"},on:{"click":function (e) {
                    _vm.$router
                      .push({
                        name: 'InventoryExport',
                        query: { id: row.Id },
                      })
                      .catch(function () {});
                  }}},[_vm._v(" "+_vm._s(_vm.$moment(row.ExportDate).format("DD/MM/YYYY HH:mm"))+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(row.WarehouseKeeper)+" ")])]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.EXPORT_STATUS_TEXT[row.Status])+" ")])],1),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":_vm.renderDetails(
                    row.InventoryExportItems,
                    row['InventoryExportItems@odata.count']
                  )}},[_vm._v(" "+_vm._s(_vm.renderDetails( row.InventoryExportItems, row["InventoryExportItems@odata.count"] ))+" ")])])])})]}}])})],1)],1),_c('detail',{attrs:{"showing":_vm.showing,"exportId":_vm.routeExportId},on:{"update:showing":function($event){_vm.showing=$event},"reload":function($event){return _vm.$refs.lstInventoryExport.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }